<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', [
      'organization',
      'user',
      'isInRole',
      'isAuthenticated',
      'userId',
      'tenantId'
    ]),
    isOrgAdmin() {
      return this.isInRole('orgadmin')
    },
    isSysAdmin() {
      return this.isInRole('sysadmin')
    },
    profileLink() {
      return '/profile'
    }
  }
}
</script>
